.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #fff;
    background-color: teal;
    border-color: #007bff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    transition: color .15s ease-in-out, border-color .15s ease-in-out, background-color .15s ease-in-out;
}

.wholepage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.every-song input[type="checkbox"] {
    position: relative;
    top: -23px;
    right: -106px;
}

.custom-file-upload:hover {
    color: black;
    background-color: rgb(236, 130, 91);
    border-color: black;
    text-decoration: none;
}

.create-playlist-button {
    background-color: teal;
    color: white;
    font-family: 'Comfortaa', sans-serif;
    padding: 15px;
    border-radius: 30px;

}

.create-playlist-button:hover {
    color: black;
    background-color: rgb(236, 130, 91);
    border-color: black;
    text-decoration: none;
}

.playlistImageBtn {
    display: none;
}

.name-name {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
}

.every-song {
    display: flex;
    flex-direction: column;
}

.create-playlist-form{
    background-color: rgb(61, 61, 61);
    padding: 15px;
    border-radius: 5px;
    width: 500px;
}

.create-playlist-form input {
    width: 620px;
}

.SubmitPlaylistBtn{
    display: flex;
    justify-content: center;
}


.upload-button:hover{
    color: rgb(236, 130, 91);
    cursor: pointer;
}
