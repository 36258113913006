.sign-up-modal{
    display: flexbox;
    padding: 50px;
}

input {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 5px;
    width: 100%;
}
