/* TODO Add site wide styles */
#modal-content{
    border-radius: 6%;
}
body {
    background-color: rgba(0, 0, 0, 0.956);
    color: darkcyan;
    font-family: 'Comfortaa', sans-serif;
    font-size: large;
}

.main-content {
    margin-top: 100px;
    margin-left: 200px;
    margin-right: 200px;
    padding: 20px;
}

.playlist-menu-dots{
    display: flex;
}

.playlist-menu-dots button {
    background-color: transparent;
    border: none;
}
.playlist-menu-dots button svg{
    fill: darkcyan;
    scale: 3
}
.playlist-play-options{
    display: flex;
    padding-top: 8px;
    padding-bottom: 28px;
    column-gap: 30px;
}
