.add-to-playlist-modal{
    padding: 40px;
    display: flex;
    flex-flow: column;
}


.add-to-playlist-modal > form{
    width: 500px;
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.add-to-playlist-modal > form > input{
    width: fit-content;
    margin: 0;
    padding: 0;
}

.SubmitAddtoPlaylist{
    border: rgba(0, 0, 0, 0.956) 1px transparent;
    background-color: teal;
    color: white;
    padding: 10px;
    border-radius: 20px;
}
.SubmitAddtoPlaylist:hover{
    border: rgba(0, 0, 0, 0.956) 1px transparent;
    background-color: rgb(236, 130, 91);
    color: black;
    padding: 10px;
    border-radius: 20px;
}

div#add-to-playlist-input-and-label{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    gap: 10px;
}

div.select-playlist{
    width: fit-content;
}


#playlist-checkbox{
    width: fit-content;
    margin-top: 12px;
}
