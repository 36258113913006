.navigation-bar {
  display: flex;
  background-color: rgb(0, 0, 0);
  position: sticky;
  top: 0;
  bottom: calc(100% - 200px);
  width: 100%;
  border-radius: 5px;
  z-index: 1;
  justify-content: space-between;
}

.profile-dropdown{
  position: absolute;
  right: 50px;
}

.navigation-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navigation-div h1 {
  margin: 0;
  font-size: 24px;
}

.navigation-div p {
  margin: 0;
  font-size: 16px;
}

.home-icon {
  size: 5rem;
}

.hidden {
  display: none
}
.LOGO{
  height:85px;
  margin-left: 10px;
  width: 80%;
}

.user-button{
  border: none;
  background-color:darkcyan;
  border-radius: 30%;
  margin-right: 10px;
}
