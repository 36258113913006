.user-profile-pic img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-left: 40px;
}

.username {
    margin-left: 0px;
    margin-bottom: 15px;
}

.user-profile-menu-dots{
    margin-left: 10px;
}

.playlistCardContainer{
    display: flex;
    width: fit-content;
}

.playlist-area{
    display: flex;
    gap: 20px;
}

.bio-playlist{
    display: flex;
    direction: row
    ;
}

.banner{
    display: flex;
    justify-content: space-between;
}

.deleteUserModal {
    height: 150px;
    padding: 15px;
}

.deleteUserModalButtons{
    display: flex;
    gap: 15px;
}
 .uploaded-songs-area {
    padding-top: 50px;
 }

#modal-content .deleteUserModal{
    padding: 100px;
    padding-top: 0;
    display: flex;
    flex-flow: column;
    border-radius: 3px;

}
