.DemoUser{
    background-color: teal;
    color: white;
    font-family: 'Comfortaa', sans-serif;
    padding: 15px;
    border-radius: 30px;
}
.DemoUser:hover{
    background-color: rgb(236, 130, 91);
    cursor: pointer;
}
.button {
    /* Add your styles here */
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #3e8e41;
    cursor: pointer;
}

.modalbtn:hover{
    cursor: pointer;
}
.RotatingPic{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.PicOnSplash{
    width: 50vh;
    height: 35vh;
    overflow: hidden;
    object-fit: cover;
    border-radius: 15%;
    box-shadow: 0px 0px 5px 5px #008080;

}
.splashScreenButtons{
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    margin-top: 40px;
    align-items: center;
    width: 1000px;
}
.SplashBottomContainer{
    display: flex;
    align-items: center;
}
.loginBtnSplash{
    border: black 1px transparent;
    box-shadow: 0px 0px 5px 1px teal;
    padding: 10px;
    border-radius: 20px;
    height: fit-content;
    width: 350px;
    background-color: rgba(0, 0, 0, 0.600);
}
.signupBtnSplash{
    display: flex;
    flex-direction: column;
    border: rgba(0, 0, 0, 0.956) 1px transparent;
    box-shadow: 0px 0px 5px 1px teal;
    padding: 10px;
    border-radius: 20px;
    height: fit-content;
    width: 350px;
    background-color: rgba(0, 0, 0, 0.600);
}
.Logincard{
    font-size: 12pt;
}
.TitleStuff{
    justify-content: center;
    text-align: center;
}
.signupbuttondiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.splash-page{
    display: flex;
    align-items: center;
    z-index: 5;
    position: absolute;
    margin: 0 auto;
}
