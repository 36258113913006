.sidebar {
    position: fixed;
    top: 88px;
    left: 0;
    bottom: 0;
    width: 200px;
    background-color: black;
    color: white;


  }

  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .sidebar ul li {
    margin-bottom: 10px;
  }

  .sidebar a {
    color: white;
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
  }
.sidebar li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 18px;
  text-align: center;
}
  .sidebar a:hover {
    background-color: teal;
    color: black;
    font-size: 18px;
  }

  .icons-bottom{
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: start;
  }

  .icons-bottom h1{
    margin-bottom: 0px;
    margin-left: 5px;
  }

  .icons-bottom a{
    font-size: 12px;
    line-height: 0px;
    padding-left: 5px;
  }

  .icons-bottom a:hover{
    color: teal;
    background-color: black;
    cursor: pointer;
  }
