#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 20;
}

#modal div{
  display: flex;
  flex-flow: column wrap;
  align-content: center
}

#modal ul{
  padding: 0;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

#modal-content {
  position: absolute;
  z-index: 20;
  background-color:rgb(61, 61, 61);
}
