.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #fff;
    background-color: teal;
    border-color: #007bff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    transition: color .15s ease-in-out,border-color .15s ease-in-out,background-color .15s ease-in-out;
}

.custom-file-upload:hover {
    color: black;
    background-color: rgb(236, 130, 91);
    border-color: black;
    text-decoration: none;
}

.create-playlist-button{
    background-color: teal;
    color: white;
    font-family: 'Comfortaa', sans-serif;
    padding: 15px;
    border-radius: 30px;
}
.create-playlist-button:hover{
    color: black;
    background-color: rgb(236, 130, 91);
    border-color: black;
    text-decoration: none;
}
.SongUploadbtn{
    display: none;
}
.SongPicupload{
    display: none;
}
.create-song-button{
    background-color: teal;
    color: white;
    font-family: 'Comfortaa', sans-serif;
    padding: 15px;
    border-radius: 30px;
}
.create-song-button:hover{
    color: black;
    background-color: rgb(236, 130, 91);
    border-color: black;
    text-decoration: none;
}

.button-for-used{
    margin-top: 10px;
    margin-bottom: 10px;
}

.wholepage div{
    margin-bottom: 40px;
}

label div {
    margin-bottom: 10px;
}

.create-song-form {
    width: 500px;
    background-color: rgb(61, 61, 61);
    padding: 15px;
    border-radius: 5px;
}
.create-song-form input{
    width: 60%;
}

.create-song-form label > div{
    margin: 0;
}

.uploadbutton:hover{
    color: rgb(236, 130, 91);
    cursor: pointer;
}
